<template>
  <div class="index positionr">
    <!-- banner -->
    <div class="banner">
      <!-- <img
        src="../../assets/images/index/banner.png"
        alt=""
        width="100%"
        class="banner"
      /> -->

      <div class="swiper-container filmswiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
             <a href="https://www.yunlankeji.com/">
              <img src="../../assets/images/index/banner01.png" alt="" />
             </a>
          </div>
          <div class="swiper-slide">
            <a href="https://www.yunlankeji.com/">
              <img src="../../assets/images/index/banner02.png" alt="" />
            </a>
          </div>
          <div class="swiper-slide">
            <a href="https://www.yunlankeji.com/">
              <img src="../../assets/images/index/banner03.png" alt="" />
            </a>
          </div>
          <div class="swiper-slide">
            <a href="https://www.yunlankeji.com/">
              <img src="../../assets/images/index/banner04.png" alt="" />
            </a>
          </div>
          <div class="swiper-slide">
            <a href="https://www.yunlankeji.com/">
              <img src="../../assets/images/index/banner05.png" alt="" />
            </a>
          </div>
          <!-- <div class="swiper-slide">
            <div class="pos3">
              <p class="f48 fw700" style="margin-bottom:30px;color:#fff">店教授-支付解决方案</p>
              <p class="f32" style="margin-bottom:10px;color:#fff">让门店收款更安全</p>
              <a href="https://www.yunlankeji.com/" target="_blink" class="zixun1">立即咨询</a>
            </div>  
            <img src="../../assets/images/index/banner3.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <div class="pos4">
              <p class="f48 fw700" style="margin-bottom:10px;color:#fff">店教授</p>
              <p class="f48 fw700" style="margin-bottom:30px;color:#fff">智慧收银系统</p>
              <a href="https://www.yunlankeji.com/" target="_blink" class="zixun2">了解详情>></a>
            </div>  
            <img src="../../assets/images/index/banner4.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <div class="pos5">
              <p class="f64 fw700" style="color:#fff">店教授收银</p>
              <a href="https://www.yunlankeji.com/" target="_blink" class="zixun2">欢迎咨询>></a>
            </div>  
            <img src="../../assets/images/index/banner5.jpg" alt="" />
          </div> -->
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <!-- 适合行业 -->
    <div class="model1">
      <div
        class="
          w1200
          flex
          align-center
          justify-between
          hp100
          animated
          fadeInLeftBig
        "
      >
        <div class="item textc">
          <img src="../../assets/images/index/Group_1.png" alt="" width="162" />
          <div class="text-blue fw600 f16">满足门店所需</div>
          <div class="mt20">
            从收银、门店管理、供应链、会员营 销到O2O，都可以用银豹一站式实现
          </div>
        </div>
        <div class="item textc">
          <img src="../../assets/images/index/Group_2.png" alt="" width="162" />
          <div class="text-blue fw600 f16">适合多种行业</div>
          <div class="mt20">
            真正做到一套系统支持零售、餐饮、生鲜、茶饮服务等行业
          </div>
        </div>
        <div class="item textc">
          <img src="../../assets/images/index/Group_3.png" alt="" width="162" />
          <div class="text-blue fw600 f16">增长你的生意</div>
          <div class="mt20">
            内置50+种生意报表和主流营销功能，深入分析生意的同时，将营销对准合适的客户
          </div>
        </div>
      </div>
    </div>
    <!-- 一站式拥有 满足门店需求 -->
    <div class="model2">
      <div class="w1200">
        <div class="textc text333 f35">一站式拥有 满足门店需求</div>
        <!-- 事件线 -->
        <div class="lines flex justify-center animated fadeIn">
          <div class="textr flex1">
            <div class="cont textr mb60">
              <p class="f15 text333">云蓝收银系统</p>
              <p class="text-blue f24 mt15 mb15">让业绩飞起来</p>
              <div class="f15 text333">
                支持多种移动支付方式，无缝集成3大外卖平台
              </div>
              <div class="f15 text333">
                真正多平台，多行业覆盖的新一代收银系统
              </div>
            </div>
            <div class="img mb90">
              <img
                src="../../assets/images/index/dateline_2.png"
                alt=""
                width="300"
              />
            </div>
            <div class="cont textr mb90" style="width: 470px">
              <p class="f15 text333">云蓝微信店铺</p>
              <p class="text-blue f24 mt15 mb15">让O2O没有门槛</p>
              <div class="f15 text333">
                自动同步你的门店商品信息到你的手机店铺，利用在线商城
              </div>
              <div class="f15 text333">
                外卖送餐、预约服务、会员服务，帮您真正实现O2O智能门店
              </div>
            </div>
            <div class="img">
              <img
                src="../../assets/images/index/dateline_4.png"
                alt=""
                width="300"
              />
            </div>
          </div>
          <div class="mid_line">
            <img
              src="../../assets/images/index/lines.png"
              alt=""
              height="920"
            />
          </div>
          <div class="lines_r textl flex1">
            <div class="img mb50">
              <img
                src="../../assets/images/index/dateline_1.png"
                alt=""
                width="300"
              />
            </div>
            <div class="cont mb90">
              <p class="f15 text333">云蓝收银后台</p>
              <p class="text-blue f24 mt15 mb15">让生意一手掌握</p>
              <div class="f15 text333">
                一个系统就能管理你的生意，多端同步，用手
              </div>
              <div class="f15 text333">
                机即可查询报表、商品、会员营销和货流尽在掌握
              </div>
            </div>
            <div class="img mb80">
              <img
                src="../../assets/images/index/dateline_3.png"
                alt=""
                width="300"
              />
            </div>
            <div class="cont">
              <p class="f15 text333">云蓝移动CMR</p>
              <p class="text-blue f24 mt15 mb15">让访客都成为回头客</p>
              <div class="f15 text333">
                在手机上随时查看自己的会员信息和消费记录，你还可以自定义
              </div>
              <div class="f15 text333">
                条件轻松筛选出活跃会员和潜水会员，一键推送营销信息
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我们的服务 -->
    <div class="model2" style="padding-bottom: 100px">
      <div class="w1100">
        <div class="textc text333 f35">我们的服务</div>
        <div class="serveBox wp100 positionr">
          <!-- 上排 -->
          <div class="flex align-center justify-between uprow">
            <div class="textc serveItem">
              <img
                src="../../assets/images/index/serve_1.png"
                alt=""
                class="serveIcon"
              />
              <div class="mt20 f15">全国范围均可提供上门安装培训服务</div>
            </div>
            <div class="textc serveItem">
              <img
                src="../../assets/images/index/serve_2.png"
                alt=""
                class="serveIcon"
              />
              <div class="mt20 f15">供完整系统功能使用视频讲解</div>
            </div>
            <div class="textc serveItem">
              <img
                src="../../assets/images/index/serve_3.png"
                alt=""
                class="serveIcon"
              />
              <div class="mt20 f15">系统软件提供特殊功能定制服务</div>
            </div>
          </div>
          <!-- 下排 -->
          <div class="flex align-center justify-around pt90">
            <div class="textc">
              <div class="text-blue">
                <span class="f58 vm">7</span>
                <span class="vm fw600">x</span>
                <span class="f58 vm">12</span>
                <span class="positionr fw600" style="top: 16px">小时</span>
              </div>
              <div class="textc mt40 f15">
                <p>30+工程师</p>
                <p>7*12小时技术支撑</p>
              </div>
            </div>

            <div class="textc">
              <div class="text-blue">
                <span class="f58">1</span>
                <span class="fw600">年</span>
              </div>
              <div class="textc mt30 f15">
                <p>硬件质保一年</p>
                <p>软件终身免费升级使用</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 都在使用云蓝收银 -->
    <div class="model3">
      <div class="w1200">
        <div class="textc text333 f35">
          <img
            src="../../assets/images/common/line_left.png"
            alt=""
            class="vm"
          />
          <span class="ml20 mr20">众多在营实体商户都在使用云蓝收银</span>
          <img
            src="../../assets/images/common/line-righ.png"
            alt=""
            class="vm"
          />
        </div>
        <!-- 图片 -->
        <div class="imagelazyBox textc pt60 pb60">
          <el-image
            v-for="url in imgurls"
            :key="url"
            :src="url"
            class="imageItem"
          ></el-image>
        </div>
      </div>
    </div>
    <!-- 支持行业 -->
    <div class="model2">
      <div class="w1200">
        <div class="textc text333 f35">支持行业</div>
        <div class="suppotBox flex align-cneter justify-between pt60 pb60">
          <div class="positionr suppotItem">
            <img
              src="../../assets/images/index/post_1.jpg"
              alt=""
              class="suppotPic"
              width="285"
              height="221"
            />
            <div class="suppot_tit">奶茶烘焙</div>
          </div>
          <div class="positionr suppotItem">
            <img
              src="../../assets/images/index/post_2.jpg"
              alt=""
              class="suppotPic"
              width="285"
              height="221"
            />
            <div class="suppot_tit">便利商店</div>
          </div>
          <div class="positionr suppotItem">
            <img
              src="../../assets/images/index/post_3.jpg"
              alt=""
              class="suppotPic"
              width="285"
              height="221"
            />
            <div class="suppot_tit">生鲜菜蔬</div>
          </div>
          <div class="positionr suppotItem">
            <img
              src="../../assets/images/index/post_4.png"
              alt=""
              class="suppotPic"
              width="285"
              height="221"
            />
            <div class="suppot_tit">餐饮行业</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 免费咨询 -->
    <free-box></free-box>
  </div>
</template>

<script>
import freebox from "@/components/freeBox";
import { Swiper,Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
// import "../../assets/swiper/swiper.css"
export default {
  data() {
    return {
      swiper: null,
      url: "",
      imgurls: [
        require("../../assets/images/index/Frame_1.png"),
        require("../../assets/images/index/Frame_2.png"),
        require("../../assets/images/index/Frame_3.png"),
        require("../../assets/images/index/Frame_4.png"),
        require("../../assets/images/index/Frame_5.png"),
        require("../../assets/images/index/Frame_6.png"),
        require("../../assets/images/index/Frame_7.png"),
        require("../../assets/images/index/Frame_8.png"),
        require("../../assets/images/index/Frame_9.png"),
        require("../../assets/images/index/Frame_10.png"),
        require("../../assets/images/index/Frame_11.png"),
        require("../../assets/images/index/Frame_12.png"),
        require("../../assets/images/index/Frame_13.png"),
        require("../../assets/images/index/Frame_14.png"),
        require("../../assets/images/index/Frame_15.png"),
        require("../../assets/images/index/Frame_16.png"),
        require("../../assets/images/index/Frame_17.png"),
        require("../../assets/images/index/Frame_18.png"),
        require("../../assets/images/index/Frame_19.png"),
        require("../../assets/images/index/Frame_20.png"),
        require("../../assets/images/index/Frame_21.png"),
        require("../../assets/images/index/Frame_22.png"),
        require("../../assets/images/index/Frame_23.png"),
        require("../../assets/images/index/Frame_24.png"),
        require("../../assets/images/index/Frame_25.png"),
        require("../../assets/images/index/Frame_26.png"),
        require("../../assets/images/index/Frame_27.png"),
        require("../../assets/images/index/Frame_28.png"),
        require("../../assets/images/index/Frame_29.png"),
        require("../../assets/images/index/Frame_30.png"),
        require("../../assets/images/index/Frame_31.png"),
        require("../../assets/images/index/Frame_32.png"),
        require("../../assets/images/index/Frame_33.png"),
        require("../../assets/images/index/Frame_34.png"),
        require("../../assets/images/index/Frame_35.png"),
        require("../../assets/images/index/Frame_36.png"),
        require("../../assets/images/index/Frame_37.png"),
        require("../../assets/images/index/Frame_38.png"),
        require("../../assets/images/index/Frame_39.png"),
        require("../../assets/images/index/Frame_40.png"),
        require("../../assets/images/index/Frame_41.png"),
        require("../../assets/images/index/Frame_42.png"),
        require("../../assets/images/index/Frame_43.png"),
        require("../../assets/images/index/Frame_44.png"),
        require("../../assets/images/index/Frame_45.png"),
        require("../../assets/images/index/Frame_46.png"),
        require("../../assets/images/index/Frame_47.png"),
        require("../../assets/images/index/Frame_48.png"),
      ],
    };
  },
  components: {
    "free-box": freebox,
  },
  created() {},
  methods: {
    initSwiper() {
      Swiper.use([Navigation, Pagination, Autoplay]);
      this.swiper = new Swiper(".filmswiper", {
        loop: true,
        speed: 600,
        spaceBetween: 21,
        preventClicksPropagation: false, //阻止默认事件
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000, //3秒切换一次
          pauseOnMouseEnter: false, //悬停停止切换
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
      });
    },
  },
  mounted() {
    this.initSwiper();
  },
  updated() {},
};
</script>

<style  scoped>
.index {
  width: 100%;
}
.model1 {
  background-color: #f7f9fa;
  height: 380px;
}
.model1 .item {
  width: 272px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  padding: 35px 20px;
  border-radius: 10px;
}
.model1 .item:hover {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.model1 .item img {
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
}
.model1 .item:hover img {
  transform: scale(1.2);
}
.model2 {
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 20px;
}
.lines {
  margin-top: 70px;
}
.mid_line {
  padding-top: 55px;
  margin: 0 50px;
}
.lines_r {
  position: relative;
  top: -40px;
}
.serveBox {
  background: url("../../assets/images/index/Frame.png") no-repeat;
  background-size: contain;
  background-position: center;
  height: 500px;
}
.serveBox .uprow {
  padding-top: 110px;
}
.serveBox .serveIcon {
  width: 90px;
  height: 90px;
}
.serveBox .serveItem div {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.serveBox .serveItem:hover .serveIcon {
  transform: rotate(360deg);
}
.serveBox .serveItem:hover div {
  /* font-weight: 700; */
  transform: scale(1.1);
  text-decoration: underline;
}
.model3 {
  background-color: #f6f8fa;
  padding-top: 100px;
  padding-bottom: 20px;
}

.imagelazyBox .imageItem:hover {
  transform: translateY(-6px) scale(1.1);
}
.suppotItem {
  width: 285px;
  height: 265px;
  overflow: hidden;
}
.suppot_tit {
  background-color: #2f2f41;
  line-height: 44px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 10;
}
.suppotItem:hover img {
  transform: scale(1.1);
}
.model4 {
  background-color: #0085ff;
  height: 370px;
}
.model4 img {
  right: 0;
  height: 100%;
}
.btn {
  display: inline-block;
  border: 1px solid #fff;
  padding: 13px 60px;
}
.banner {
  widows: 100%;
  height: calc(100vh - 70px);
  /* background-color: pink; */
}
.banner .swiper-container{
  width: 100%;
  height: 100%;
  /* position: relative; */
}
.banner .swiper-container .swiper-wrapper .swiper-slide{
  position: relative;
}
.banner .swiper-container .swiper-wrapper .swiper-slide img{
  width: 100%;
  height: 100%;
}
.pos1{
  color: #FFF;
  position: absolute;
  top: 46%;
  left: 20%;
  border-left: 10px solid #fff;
  padding-left: 40px;
}
.pos2{
  position: absolute;
  top: 35%;
  left: 12%;
}
.pos2 .zixun{
  display: block;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: rgba(20, 17, 17, 0.8);
  border-radius: 30px;
  color: #fff;
  font-size: 24px;
  margin-top: 50px;

}
.pos3{
  position: absolute;
  top: 35%;
  left: 20%;
}
.pos3 .zixun1{
  display: block;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color:#FFF;
  border-radius: 10px;
  color: #000;
  font-size: 24px;
  margin-top: 50px;

}
.pos3 .zixun1:hover{
  background-color:rgb(22, 97, 255);
  color:#fff;
}
.pos4{
  position: absolute;
  top: 30%;
  left: 52%;
}
.pos4 .zixun2{
  display: block;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color:transparent;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 24px;
  margin-top: 50px;

}
.pos5{
  position: absolute;
  top: 50%;
  left: 62%;
}
.pos5 .zixun2{
  display: block;
  width: 200px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color:transparent;
  border-radius: 30px;
  color: #fff;
  font-size: 32px;
  margin-top: 20px;

}
.swiper-container  /deep/ .swiper-pagination-bullet {
  height:3px !important;
  width:80px !important;
  border-radius:0 !important;
}
</style>
