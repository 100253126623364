<template>
  <!-- 免费咨询 -->
  <div class="freeBox positionr">
    <img src="../assets/images/common/system.png" alt="" class="pic animated fadeIn" />
    <div class="text-white positiona freeCont hp100">
      <div class="w1200 flex flex-column align-start justify-center hp100 animated bounceInLeft">
        <div>
          <div class="f32">专业打造您的专属门店收银系统</div>
          <div class="f18 mt30">
            练习客服，并提出您的需求，我们将会有产品经理为您量身定制解决方案
          </div>
          <div class="lineBtn mt50 transition pointer">免费咨询</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoded>
.freeBox{
  width: 100%;
}
.freeBox img{
  width: 100%;
}
.freeCont {
  top: 0;
  left: 0;
  width: 100%;
}
.lineBtn:hover{
  transform: translateY(-6px);
  box-shadow: 8px 9px 5px rgba(0, 0, 0, 0.3);
}
</style>